import {
    resourceGoals,
    resourceIndustries,
    resourceTags,
    spotonProducts,
  } from "../../constants";
  
  export default {
    pageTitle: "SpotOn Case Study | Flowers on Phoenix",
    pageDescription:
      "Find out how a small-scale flower farm streamlines transactions, markets to customers, and saves hours every week with SpotOn technology.",
    title: "Growing a flower business from the ground up",
    subTitle: `How Flowers on Phoenix simplifies operations and connects with customers using SpotOn.`,
    businessName: "Flowers on Phoenix",
    industry: "Retail",
    location: "Tipton, Missouri",
    products: [spotonProducts.DASHBOARD],
    description:
      "Flowers on Phoenix is a small-scale flower farm that gives customers the experience of picking their own blooms right from the garden. With SpotOn technology, owner Amanda Keene can streamline her checkout process, build customer relationships, and boost sales through intuitive marketing campaigns.",
    quoteData: {
      imgName: "flowers-on-phoenix.png",
      quote: `“I like the fact that I can keep track of everything on my SpotOn Dashboard. It’s my first year of doing business, and I wanted to make sure everything was aligned. SpotOn is really awesome at making it so simple and easy to access sales information.”`,
      personName: "Amanda Keene",
      personTitle: "Owner, Flowers on Phoenix",
    },
    goal: `Keene was looking for a payment solution that would start her small business off on the right foot with professional transaction and sales reporting tools. As a busy mom, she also was also looking for solutions that would minimize time spent on admin and troubleshooting.`,
    solution: `With SpotOn, Keene can take payments and collect customer data in one transaction for effortless email list building. She can also track sales trends and send marketing campaigns in minutes through the SpotOn Dashboard, saving her hours every week.`,
    results: {
      title: `The results`,
      stats: [
        { title: `5 hours`, description: `saved on weekly admin tasks` },
        { title: `3-minute`, description: `faster checkout processes` },
      ],
    },
  };
  
  export const relatedSuccessStories = [
    {
        title: "A blooming nursery gives customers a faster experience",
        imageName: "unique-plants-and-palms.png",
        industry: resourceIndustries.RETAIL,
        tag: resourceTags.CASE_STUDY,
        date: "2022-07-13",
        href: "/case-studies/unique-plants-and-palms",
        goals: [
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.GET_SMARTER_DATA,
          resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
        ],
    },
    {
        title: "Getting more regulars with rewards",
        imageName: "strows-dry-cleaners.png",
        industry: resourceIndustries.RETAIL,
        tag: resourceTags.CASE_STUDY,
        date: "2022-05-27",
        href: "/case-studies/strows-dry-cleaners",
        goals: [
          resourceGoals.BUILD_CUSTOMER_LOYALTY,
          resourceGoals.GET_SMARTER_DATA,
        ],
    },
    {
        title: "Imagining gift baskets online",
        imageName: "imagine-that.png",
        industry: resourceIndustries.RETAIL,
        tag: resourceTags.CASE_STUDY,
        date: "2022-03-17",
        href: "/case-studies/imagine-that",
        goals: [
          resourceGoals.CONNECT_AND_SELL_ONLINE,
          resourceGoals.REACH_NEW_CUSTOMERS,
        ],
    },
  ];
  